import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { deleteSchool } from '../../features/schools';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  IconButton,
  Popover,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeliverySchedulePDF from './DeliverySchedulePDF'; // Adjust the path

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import { fetchOrganizations } from '../../features/organizations';
import ConfirmationDialog from '../ConfirmationDialog'; // Import the component
import MapComponent from './MapComponent'; // Adjust the path as needed
import { formatPhone } from '../../utils/generalUtils';
import { useRoles } from '../../utils/authUtils'; // Import the custom hook

const SchoolList = ({ schools, onEdit }) => {
  const { user } = useSelector((state) => state.auth);
  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  const dispatch = useDispatch();
  const organizations =
    useSelector((state) => state.organizations.organizations) || [];
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedSchoolType, setSelectedSchoolType] = useState(''); // New state for school type
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(''); // New state for delivery date
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
  const [schoolToDelete, setSchoolToDelete] = useState(null); // State for the school to delete

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteSchool(id));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  const handleSchoolTypeChange = (event) => {
    setSelectedSchoolType(event.target.value);
  };

  const handleDeliveryDateChange = (event) => {
    setSelectedDeliveryDate(event.target.value);
  };

  const handleSchoolClick = (event, school) => {
    setSelectedSchool(school);
    setAnchorEl(anchorRef.current); // Use anchorRef to set the anchor element
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedSchool(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteClick = (school) => {
    setSchoolToDelete(school);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(schoolToDelete._id);
    setDialogOpen(false);
    setSchoolToDelete(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Extract unique school types and delivery dates
  const uniqueSchoolTypes = [
    ...new Set(schools.map((school) => school.schoolType)),
  ];
  const uniqueDeliveryDates = [
    ...new Set(schools.map((school) => school.deliveryDate)),
  ];

  // const filteredSchools = schools.filter((school) => {
  //   const matchesName = school.name
  //     .toLowerCase()
  //     .includes(filter.toLowerCase());
  //   const matchesOrg =
  //     selectedOrg === '' || school.organization._id === selectedOrg._id;
  //   return matchesName && matchesOrg;
  // });

  const filteredSchools = schools.filter((school) => {
    const matchesName = school.name
      .toLowerCase()
      .includes(filter.toLowerCase());
    const matchesOrg =
      selectedOrg === '' || school.organization._id === selectedOrg._id;
    const matchesSchoolType =
      selectedSchoolType === '' || school.schoolType === selectedSchoolType;
    const matchesDeliveryDate =
      selectedDeliveryDate === '' ||
      school.deliveryDate === selectedDeliveryDate;

    return (
      matchesName && matchesOrg && matchesSchoolType && matchesDeliveryDate
    );
  });

  const sortedSchools = filteredSchools.sort((a, b) => {
    if (
      orderBy === 'name' ||
      orderBy === 'shortName' ||
      orderBy === 'schoolType'
    ) {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
    if (orderBy === 'deliveryDate') {
      const dayA = daysOfWeek.indexOf(a.deliveryDate);
      const dayB = daysOfWeek.indexOf(b.deliveryDate);
      return order === 'asc' ? dayA - dayB : dayB - dayA;
    }
    if (orderBy === 'population') {
      return order === 'asc'
        ? a.population - b.population
        : b.population - a.population;
    }
    return 0;
  });

  const paginatedSchools = sortedSchools.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const generateTelLink = (phoneNumber) => {
    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return `tel:${cleaned}`;
  };

  // Create a reference for the anchor element
  const anchorRef = useRef(null);

  return (
    <div>
      <h2>School List</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='filtering-options-content'
          id='filtering-options-header'
        >
          <Typography variant='h6'>Filtering Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display='flex' gap={1} justifyContent='space-between'>
            <FormControl fullWidth margin='dense'>
              <InputLabel>Organization</InputLabel>
              <Select
                value={selectedOrg}
                onChange={handleOrgChange}
                label='Organization'
              >
                <MenuItem value=''>All</MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org._id} value={org}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel>School Type</InputLabel>
              <Select
                value={selectedSchoolType}
                onChange={handleSchoolTypeChange}
                label='School Type'
              >
                <MenuItem value=''>All</MenuItem>
                {uniqueSchoolTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <InputLabel>Delivery Date</InputLabel>
              <Select
                value={selectedDeliveryDate}
                onChange={handleDeliveryDateChange}
                label='Delivery Date'
              >
                <MenuItem key='all' value=''>
                  All
                </MenuItem>
                <MenuItem key='all'>Not Set</MenuItem>
                {daysOfWeek.map((date) => (
                  <MenuItem key={date} value={date}>
                    {date ? date : '--Not Set--'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </AccordionDetails>
      </Accordion>
      <TextField
        label='Search Schools'
        variant='outlined'
        fullWidth
        margin='dense'
        value={filter}
        onChange={handleFilterChange}
      />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <Typography
          variant='subtitle2'
          gutterBottom
          align='center'
          ref={anchorRef}
        >
          {filteredSchools.length} schools
        </Typography>
        <DeliverySchedulePDF schools={filteredSchools} />
      </Box>

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Short Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'population'}
                  direction={orderBy === 'population' ? order : 'asc'}
                  onClick={() => handleRequestSort('population')}
                >
                  Population
                </TableSortLabel>
              </TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'schoolType'}
                  direction={orderBy === 'schoolType' ? order : 'asc'}
                  onClick={() => handleRequestSort('schoolType')}
                >
                  School Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'deliveryDate'}
                  direction={orderBy === 'deliveryDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('deliveryDate')}
                >
                  Delivery Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Location</TableCell>
              {(isAdmin || isManager || isLTEAdmin) && (
                <TableCell align='right'>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedSchools.map((school) => (
              <TableRow key={school._id}>
                <TableCell>{school.name}</TableCell>
                <TableCell>{school.shortName}</TableCell>
                <TableCell>{school.population}</TableCell>
                <TableCell>
                  <Link
                    href={`https://${school.website}`}
                    rel='noopener noreferrer nofollow'
                    sx={{ color: 'primary' }}
                  >
                    {school.website}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={generateTelLink(school.phone)} color='inherit'>
                    {formatPhone(school.phone)}
                  </Link>
                </TableCell>
                <TableCell>{school.schoolType}</TableCell>
                <TableCell>{school.deliveryDate || 'Not set'}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(event) => handleSchoolClick(event, school)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <MapIcon />
                  </IconButton>
                </TableCell>
                {(isAdmin || isManager || isLTEAdmin) && (
                  <TableCell align='right'>
                    <IconButton
                      edge='end'
                      aria-label='edit'
                      onClick={() => onEdit(school)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge='end'
                      color='secondary'
                      aria-label='delete'
                      onClick={() => handleDeleteClick(school)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        component='div'
        count={filteredSchools.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableAutoFocus
        disableRestoreFocus
      >
        {selectedSchool && anchorEl && (
          <Box p={2}>
            {selectedSchool && (
              <MapComponent
                center={{
                  lat: selectedSchool.latitude, // Replace with actual latitude property
                  lng: selectedSchool.longitude, // Replace with actual longitude property
                }}
              />
            )}
          </Box>
        )}
      </Popover>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title='Delete School'
        message={`Are you sure you want to delete the school "${schoolToDelete?.name}"?`}
      />
    </div>
  );
};

export default SchoolList;
