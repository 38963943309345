import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, updateUser } from '../../features/users';

import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  FormControl,
  FormControlLabel,
  Alert,
  Box,
  Chip,
} from '@mui/material';
import { fetchOrganizations } from '../../features/organizations';
import { fetchSchools, fetchAllSchools } from '../../features/schools';
import { fetchRoles } from '../../features/roles';
import ImageUploader from '../Images/ImageUpload';
import { showToastError, showToastSuccess } from '../../utils/toastUtils';
import { useRoles } from '../../utils/authUtils'; // Import the custom hook

const UserForm = ({ user, open, onClose }) => {
  const dispatch = useDispatch();

  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  // Fetch data from Redux store
  const organizations =
    useSelector((state) => state.organizations.organizations) || [];
  const roles = useSelector((state) => state.roles.roles) || [];
  const schools = useSelector((state) => state.schools.schools) || [];
  const allSchools = useSelector((state) => state.schools.allSchools) || [];

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchOrganizations());
    dispatch(fetchRoles());
    dispatch(fetchSchools());
    dispatch(fetchAllSchools());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      setEmail(user.email || '');
      setPhone(user.phone || '');
      setAddress(user.address || '');
      setWebsite(user.website || '');
      setSelectedOrganizations(
        user.organizations ? user.organizations.map((org) => org._id) : [],
      );
      setSelectedSchools(
        user.schools ? user.schools.map((school) => school._id) : [],
      );
      setSelectedRoles(user.roles.map((role) => role._id) || []);
      setSelectedRoles(user.roles.map((role) => role._id) || []);
      setSendWelcomeEmail(user.sendWelcomeEmail || false);
    } else {
      resetForm();
    }
  }, [user]);

  useEffect(() => {
    const errors = {};

    // Validate first name
    if (!firstName) errors.firstName = 'First Name is required';

    // Validate last name
    if (!lastName) errors.lastName = 'Last Name is required';

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Email is not valid';
    }

    // Validate phone
    const cleanedPhone = phone.replace(/\D/g, ''); // Remove non-numeric characters
    if (!phone) {
      errors.phone = 'Phone is required';
    } else if (cleanedPhone.length !== 10) {
      errors.phone = 'Phone must be 10 digits';
    }

    setValidationErrors(errors);
  }, [firstName, lastName, email, phone]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('website', website);
    formData.append('organizations', JSON.stringify(selectedOrganizations));
    formData.append('roles', JSON.stringify(selectedRoles));
    formData.append('schools', JSON.stringify(selectedSchools));
    formData.append('sendWelcomeEmail', sendWelcomeEmail);

    let imageInfo;
    if (!selectedFile && user !== null) {
      imageInfo = user.imageInfo;
      formData.append('imageInfo', JSON.stringify(imageInfo));
    } else if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      let response;

      if (user) {
        formData.append('_id', user._id);
        response = await dispatch(updateUser(formData));
      } else {
        response = await dispatch(addUser(formData));
      }

      if (response && response.payload.success) {
        if (user) {
          showToastSuccess(
            `${user.firstName} ${user.lastName} updated successfully`,
          );
        } else {
          showToastSuccess(`${firstName} ${lastName} created successfully`);
        }

        resetForm();
        onClose();
        setIsSubmitted(false);
      } else {
        throw new Error(
          response.payload.message || 'An error occurred. Please try again.',
        );
      }
    } catch (error) {
      setError(error.message || 'An error occurred. Please try again.');
      showToastError(`An error occurred: ${error.message}`);
    }
  };

  const filteredSchools = schools?.filter((school) =>
    selectedOrganizations.includes(school.organization?._id),
  );

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setWebsite('');
    setSelectedOrganizations([]);
    setSelectedRoles([]);
    setSelectedSchools([]);
    setSendWelcomeEmail(false);
    setSelectedFile(null);
    setError('');
    setValidationErrors({});
  };

  const handleRoleChange = (event) => {
    const value = event.target.value;
    setSelectedRoles(value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{user ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        {error && <Alert severity='error'>{error}</Alert>}
        <TextField
          margin='dense'
          label='First Name'
          fullWidth
          variant='outlined'
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setValidationErrors((prev) => ({ ...prev, firstName: '' }));
          }}
          error={isSubmitted && !!validationErrors.firstName}
          helperText={isSubmitted && validationErrors.firstName}
        />
        <TextField
          margin='dense'
          label='Last Name'
          fullWidth
          variant='outlined'
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setValidationErrors((prev) => ({ ...prev, lastName: '' }));
          }}
          error={isSubmitted && !!validationErrors.lastName}
          helperText={isSubmitted && validationErrors.lastName}
        />
        <TextField
          margin='dense'
          label='Email'
          fullWidth
          variant='outlined'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setValidationErrors((prev) => ({ ...prev, email: '' }));
          }}
          error={isSubmitted && !!validationErrors.email}
          helperText={isSubmitted && validationErrors.email}
        />
        <TextField
          margin='dense'
          label='Phone'
          fullWidth
          variant='outlined'
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            setValidationErrors((prev) => ({ ...prev, phone: '' }));
          }}
          error={isSubmitted && !!validationErrors.phone}
          helperText={isSubmitted && validationErrors.phone}
        />
        {/* <TextField
          margin='dense'
          label='Address'
          fullWidth
          variant='outlined'
          value={address}
          onChange={(e) => {
            setEmail(e.target.value);
            setValidationErrors((prev) => ({ ...prev, address: '' }));
          }}
          error={!!validationErrors.address}
          helperText={isSubmitted && validationErrors.address}
        /> */}

        {isLTEAdmin ? (
          <FormControl fullWidth margin='dense'>
            <InputLabel>Organizations</InputLabel>
            <Select
              multiple
              value={selectedOrganizations}
              onChange={(e) => setSelectedOrganizations(e.target.value)}
              label='Organization'
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value, index) => (
                    <Chip
                      key={index}
                      label={
                        organizations.find((org) => org._id === value)?.name
                      }
                    />
                  ))}
                </Box>
              )}
            >
              {organizations.map((org) => (
                <MenuItem key={org._id} value={org._id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant='subtitle2' sx={{ mt: 1, mb: 0 }}>
              Organization
            </Typography>
            {organizations.map((org) => (
              <Typography key={org._id} sx={{ mb: 1, pl: 2 }}>
                {org.name}
              </Typography>
            ))}
          </>
        )}
        {isAdmin || isManager || isLTEAdmin ? (
          <FormControl fullWidth margin='dense'>
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRoles}
              onChange={handleRoleChange}
              label='Role'
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={roles.find((role) => role._id === value)?.name}
                    />
                  ))}
                </Box>
              )}
            >
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant='subtitle2' sx={{ mb: 0 }}>
              Roles
            </Typography>
            {selectedRoles.map((role) => (
              <Typography sx={{ mb: 2, pl: 2 }} key={role._id}>
                {roles.find((roleList) => roleList._id === role)?.name}
              </Typography>
            ))}
          </>
        )}
        <FormControl fullWidth margin='dense'>
          <InputLabel>Schools</InputLabel>
          <Select
            multiple
            value={selectedSchools}
            onChange={(e) => setSelectedSchools(e.target.value)}
            label='School'
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip
                    key={index}
                    label={allSchools.find((org) => org._id === value)?.name}
                  />
                ))}
              </Box>
            )}
          >
            {allSchools.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Reusable ImageUpload component */}
        <ImageUploader
          onFileSelect={setSelectedFile}
          initialImageURL={user?.imageInfo?.url}
        />
        {isAdmin ||
          isManager ||
          (isLTEAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendWelcomeEmail}
                  onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                />
              }
              label='Send Welcome Email'
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' variant='contained'>
          {user ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;
