import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import StepNavigationButtons from './StepNavigationButtons';
import { getNextMondays } from '../../utils/dateUtils';
const OrderWeekStep = ({
  weekOf,
  school,
  setWeekOf,
  setActiveStep,
  setDeliveryDate,
}) => {
  const [selectedWeek, setSelectedWeek] = useState(weekOf);
  const mondays = getNextMondays();

  useEffect(() => {
    // if weekOf is not set, set it to the first Monday in the array
    if (!weekOf) {
      handleWeekClick(mondays[0]);
    }
  }, [weekOf, setWeekOf, mondays]);

  useEffect(() => {
    setSelectedWeek(weekOf); // Update selectedWeek when weekOf changes
  }, [weekOf]);

  const handleWeekClick = (week) => {
    setWeekOf(week);
    setSelectedWeek(week); // Update the selected week state
    const deliveryDate = findDeliveryDate(school.deliveryDate, week); // Set the delivery date to the selected week
    setDeliveryDate(deliveryDate);
  };

  const findDeliveryDate = (deliveryDay, weekOf) => {
    // Mapping of day names to day numbers
    const dayMapping = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    // Get the day number for the delivery day
    const deliveryDayNumber = dayMapping[deliveryDay];

    // Create a new Date object for the start of the week
    const startOfWeek = new Date(weekOf);

    // Get the day of the week for the start of the week (0 = Sunday, 1 = Monday, etc.)
    const startDay = startOfWeek.getDay();

    // Calculate the difference in days between the start of the week and the delivery day
    const dayDifference = deliveryDayNumber - startDay;

    // Set the delivery date by adding the day difference to the start of the week
    const deliveryDate = new Date(startOfWeek);
    deliveryDate.setDate(startOfWeek.getDate() + dayDifference);

    return deliveryDate;
  };

  const handlePreviousStep = () => {
    // Navigate to the "School Contact" step
    setActiveStep(1); // Set this to the step index of the "School Contact" step
  };

  const handleNextStep = () => {
    if (weekOf) {
      setActiveStep(3); // Navigate to the next step if a week is selected
    }
  };
  return (
    <Box>
      <Box>
        <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>
          Select Order Week
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant='subtitle1' sx={{ mb: 2 }}>
          Delivery Date for {school.name} is on
          <span style={{ fontSize: '1.2rem', color: 'darkmagenta' }}>
            {' '}
            {school.deliveryDate}s
          </span>
        </Typography>
      </Box>
      {mondays.map((monday) => (
        <Button
          key={monday}
          onClick={() => handleWeekClick(monday)}
          variant={selectedWeek === monday ? 'contained' : 'outlined'}
          color='primary'
          sx={{
            mr: 1,
            mb: 1,
            // Highlight the selected button
            ...(new Date(selectedWeek).toDateString() === monday && {
              backgroundColor: 'primary.main',
              color: 'white',
              borderColor: 'primary.main',
            }),
          }}
        >
          {monday}
        </Button>
      ))}

      <Typography variant='subtitle1' sx={{ mt: 1, mb: 3 }}>
        Orders must be in by 4pm on Mondays for the following week deliveries
      </Typography>

      <StepNavigationButtons
        previousStep={handlePreviousStep}
        nextStep={handleNextStep}
        nextDisabled={!weekOf}
        nextText={
          !weekOf ? 'Select an Order Week ...' : 'Next Step - Select Foods'
        }
      />
    </Box>
  );
};

export default OrderWeekStep;
