import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showToastSuccess } from '../utils/toastUtils';
import apiServices from '../api';
import {
  clearLocalStorage,
  handleAuthError,
  handleAuthTokenCheck,
  saveToken,
  getToken,
} from '../utils/authUtils';
import { STORAGE_KEYS } from '../constants/storageKeys';

const getInitialUserState = () =>
  JSON.parse(localStorage.getItem(STORAGE_KEYS.user)) || null;

// Fetch current user using apiServices
export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const token = getToken();

      const tokenError = handleAuthTokenCheck(token, rejectWithValue);
      if (tokenError) return tokenError;

      const response = await apiServices.authApi.getCurrentUser(token);
      return response.data; // Return user data
    } catch (error) {
      return handleAuthError(error, rejectWithValue);
    }
  },
);

export const login = createAsyncThunk('auth/login', async (credentials) => {
  const response = await apiServices.authApi.login(credentials);
  saveToken(response.data.token);

  // Save user info and image in local storage
  const user = response.data.user;
  localStorage.setItem('user', JSON.stringify(user));
  return response.data.user;
});

export const register = createAsyncThunk(
  'auth/register',
  async (user, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await apiServices.authApi.register(user, config);

      showToastSuccess(
        'Account Created. You will be notified when your account is active.',
      );

      return response.data.user;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue('Something went wrong. Please try again.');
      }
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: getInitialUserState(),
    status: 'idle',
    error: null,
  },
  reducers: {
    logout: (state) => {
      clearLocalStorage();
      state.user = null;
      state.status = 'idle';
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null; // Clear user state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })

      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.user = null;
      });
  },
});

export const { logout, clearUser } = authSlice.actions;

export default authSlice.reducer;
