import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Modal,
} from '@mui/material';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const FoodOrderPrintForm = ({ open, handleClose, selectedFoodOrder }) => {
  const [isPrintReady, setIsPrintReady] = useState(false);
  const printRef = useRef(null);

  const showPrices =
    selectedFoodOrder?.school.schoolType === 'Elementary' ? false : true;

  useEffect(() => {
    // When modal opens, ensure content is ready before allowing print
    if (open) {
      const timer = setTimeout(() => {
        if (printRef.current) {
          setIsPrintReady(true);
        }
      }, 500); // Give enough time for content to render

      return () => {
        clearTimeout(timer);
        setIsPrintReady(false);
      };
    }
  }, [open, selectedFoodOrder]);

  const handlePrint = async () => {
    if (!isPrintReady || !printRef.current) {
      console.error('Print content not ready');
      return;
    }

    try {
      const content = printRef.current;
      const printWindow = window.open('', '_blank');

      if (!printWindow) {
        alert('Please allow pop-ups to print');
        return;
      }

      // Add print-specific styles
      printWindow.document.write(`
        <html>
          <head>
            <title>Order Print Form</title>
            <style>
              body { 
                font-family: Roboto, Arial, sans-serif; 
                padding: 20px;
                color: rgba(0, 0, 0, 0.87);
              }
              table { 
                width: 100%; 
                border-collapse: collapse; 
                margin: 16px 0;
              }
              th, td { 
                border: 1px solid rgba(224, 224, 224, 1);
                padding: 16px;
                text-align: left;
              }
              th { 
                background-color: #f5f5f5;
                font-weight: 500;
              }
              @media print {
                button { display: none !important; }
                @page { margin: 20mm; }
              }
            </style>
          </head>
          <body>
            ${content.outerHTML}
          </body>
        </html>
      `);

      printWindow.document.close();

      // Wait for content to load before printing
      printWindow.onload = () => {
        printWindow.print();
        // Close the window after printing or if print is cancelled
        setTimeout(() => {
          printWindow.close();
        }, 100);
      };
    } catch (error) {
      console.error('Print failed:', error);
      alert('Failed to print. Please try again.');
    }
  };

  // Separate items into Pre-Made and others
  const premadeItems = selectedFoodOrder?.items.filter(
    (item) => item?.menuItem?.category === 'Pre-Made',
  );
  const otherItems = selectedFoodOrder?.items.filter(
    (item) => item?.menuItem?.category !== 'Pre-Made',
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='order-print-form-title'
      aria-describedby='order-print-form-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box ref={printRef}>
          {/* Print Header */}
          <Typography variant='h2' sx={{ fontWeight: 'bold', mb: 2 }}>
            Order Print Form
          </Typography>

          {/* School Information */}
          <Box sx={{ mb: 3 }}>
            <Typography variant='body1' sx={{ mb: 1 }}>
              <Typography
                component='span'
                variant='body1'
                sx={{ fontWeight: 'bold' }}
              >
                School:
              </Typography>{' '}
              {selectedFoodOrder?.school?.name}
            </Typography>
            <Typography variant='body1' sx={{ mb: 1 }}>
              <Typography
                component='span'
                variant='body1'
                sx={{ fontWeight: 'bold' }}
              >
                Week Of:
              </Typography>{' '}
              {new Date(selectedFoodOrder?.weekOf).toLocaleDateString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </Typography>

            {selectedFoodOrder?.deliveryDate && (
              <Typography variant='body1' sx={{ mb: 1 }}>
                <Typography
                  component='span'
                  variant='body1'
                  sx={{ fontWeight: 'bold' }}
                >
                  Delivery Date:
                </Typography>{' '}
                {new Date(selectedFoodOrder?.deliveryDate).toLocaleDateString(
                  'en-US',
                  {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                )}
              </Typography>
            )}
            <Typography variant='body1' sx={{ mb: 1 }}>
              <Typography
                component='span'
                variant='body1'
                sx={{ fontWeight: 'bold' }}
              >
                Status:
              </Typography>{' '}
              {selectedFoodOrder?.status}
            </Typography>
          </Box>

          {/* Order Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Item</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Category</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Quantity</strong>
                  </TableCell>
                  {selectedFoodOrder?.items?.some(
                    (item) => item.menuItem.category === 'Pre-Made',
                  ) &&
                    daysOfWeek.map((day) => (
                      <TableCell key={day}>
                        <strong>{day}</strong>
                      </TableCell>
                    ))}
                  {showPrices && (
                    <>
                      <TableCell>
                        <strong>Unit Price</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Total</strong>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {premadeItems.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{ fontWeight: 'bold', backgroundColor: '#d9e8f5' }}
                      >
                        Pre-Made Items
                      </TableCell>
                    </TableRow>
                    {premadeItems.map((item, index) => (
                      // {selectedFoodOrder?.items?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.menuItem.name}</TableCell>
                        <TableCell>{item.menuItem.category}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        {daysOfWeek.map((day) => (
                          <TableCell key={day}>
                            {item[`${day.toLowerCase()}Quantity`]}
                          </TableCell>
                        ))}
                        {showPrices && (
                          <>
                            <TableCell>
                              ${item.menuItem.price?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                              $
                              {(item.menuItem.price * item.quantity).toFixed(2)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </>
                )}
                {otherItems.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{ fontWeight: 'bold', backgroundColor: '#d9e8f5' }}
                      >
                        Other Items
                      </TableCell>
                    </TableRow>

                    {otherItems.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.menuItem.name}</TableCell>
                        <TableCell>{item.menuItem.category}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        {showPrices && (
                          <>
                            <TableCell>${item.price?.toFixed(2)}</TableCell>
                            <TableCell>
                              ${(item.price * item.quantity).toFixed(2)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Totals */}
          {showPrices && (
            <Box sx={{ mt: 3 }}>
              <Typography variant='body1' sx={{ mb: 1 }}>
                <strong>Subtotal:</strong> $
                {selectedFoodOrder?.subTotalAmount?.toFixed(2)}
              </Typography>
              <Typography variant='body1' sx={{ mb: 1 }}>
                <strong>Surcharge:</strong> $
                {selectedFoodOrder?.surchargeTotal?.toFixed(2)}
              </Typography>
              <Typography variant='body1' sx={{ mb: 1 }}>
                <strong>Total Amount:</strong> $
                {selectedFoodOrder?.totalAmount?.toFixed(2)}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant='outlined' onClick={handleClose}>
            Close
          </Button>
          <Button
            variant='contained'
            onClick={handlePrint}
            disabled={!isPrintReady}
          >
            Print
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FoodOrderPrintForm;
