import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import ConfirmationDialog from '../ConfirmationDialog'; // Adjust the import path as needed
import { fetchFoodOrders } from '../../features/foodOrders';
import { useRoles } from '../../utils/authUtils'; // Import the custom hook

const FoodOrderList = ({ onPrint, onEdit, onDelete }) => {
  const foodOrders = useSelector((state) => state.foodOrders.foodOrders);
  const dispatch = useDispatch();
  const [order] = useState('asc');
  const [sortOrder, setSortOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('orderDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [loadHistoricOrders, setLoadHistoricOrders] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  useEffect(() => {
    // Fetch food orders
    dispatch(fetchFoodOrders(loadHistoricOrders));
  }, [dispatch, loadHistoricOrders]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Open dialog with selected order
  const handleDeleteConfirmation = (order) => {
    setOrderToDelete(order);
    setDialogOpen(true);
  };

  // Close dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setOrderToDelete(null);
  };

  // Confirm delete action
  const confirmDelete = () => {
    if (orderToDelete) {
      onDelete(orderToDelete);
    }
    handleDialogClose();
  };

  const handleLoadHistoricOrders = () => {
    setLoadHistoricOrders((prev) => !prev);
  };

  // Filter foodOrders
  const filteredOrders = foodOrders.filter(
    (o) =>
      (o.school.name?.toLowerCase().includes(filter.toLowerCase()) ||
        o.items.some((item) =>
          item.menuItem.name?.toLowerCase().includes(filter.toLowerCase()),
        )) &&
      (statusFilter ? o.status === statusFilter : true),
  );
  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setSelectedStatus(newStatus);
      setStatusFilter(newStatus === 'All' ? '' : newStatus);
    }
  };

  // Sort foodOrders
  const sortedOrders = filteredOrders.sort((a, b) => {
    if (orderBy === 'orderDate') {
      return sortOrder === 'asc'
        ? new Date(a.orderDate) - new Date(b.orderDate)
        : new Date(b.orderDate) - new Date(a.orderDate);
    }
    if (orderBy === 'weekOf') {
      return sortOrder === 'asc'
        ? new Date(a.weekOf) - new Date(b.weekOf)
        : new Date(b.weekOf) - new Date(a.weekOf);
    }
    if (orderBy === 'totalAmount') {
      return sortOrder === 'asc'
        ? a.totalAmount - b.totalAmount
        : b.totalAmount - a.totalAmount;
    }
    if (orderBy === 'deliveryDate') {
      return sortOrder === 'asc'
        ? (a.deliveryDate ? new Date(a.deliveryDate) : Infinity) -
            (b.deliveryDate ? new Date(b.deliveryDate) : Infinity)
        : (b.deliveryDate ? new Date(b.deliveryDate) : Infinity) -
            (a.deliveryDate ? new Date(a.deliveryDate) : Infinity);
    }
    if (orderBy === 'school') {
      return sortOrder === 'asc'
        ? a.school.name.localeCompare(b.school.name)
        : b.school.name.localeCompare(a.school.name);
    }
    if (orderBy === 'status') {
      return sortOrder === 'asc'
        ? a.status.localeCompare(b.status)
        : b.status.localeCompare(a.status);
    }
    return 0;
  });

  const paginatedOrders = sortedOrders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const hasHighSchools = paginatedOrders.some(
    (o) => o.school?.schoolType === 'High',
  );

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          marginBottom: '16px',
        }}
      >
        <TextField
          label='Search Orders'
          widdth='100%'
          variant='outlined'
          margin='dense'
          value={filter}
          sx={{ flexGrow: 1 }}
          onChange={handleFilterChange}
        />
      </Box>
      <Button
        onClick={handleLoadHistoricOrders}
        variant={loadHistoricOrders ? 'contained' : 'outlined'}
        color={loadHistoricOrders ? 'secondary' : 'primary'}
        sx={{ my: 2 }}
      >
        {loadHistoricOrders ? 'Hide' : 'Load'} Historic Orders (older than 30
        days)
      </Button>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ToggleButtonGroup
          value={selectedStatus}
          exclusive
          onChange={handleStatusChange}
          aria-label='order status'
        >
          <ToggleButton value='All' aria-label='all orders'>
            All
          </ToggleButton>
          <ToggleButton value='New' aria-label='new orders'>
            New
          </ToggleButton>
          <ToggleButton value='Pending' aria-label='pending orders'>
            Pending
          </ToggleButton>
          <ToggleButton value='Modified' aria-label='modified orders'>
            Modified
          </ToggleButton>
          <ToggleButton value='Completed' aria-label='completed orders'>
            Completed
          </ToggleButton>
          <ToggleButton value='Cancelled' aria-label='cancelled orders'>
            Cancelled
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'orderDate'}
                  direction={orderBy === 'orderDate' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('orderDate')}
                >
                  Order Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'weekOf'}
                  direction={orderBy === 'weekOf' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('weekOf')}
                >
                  Week Of
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'school'}
                  direction={orderBy === 'school' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('school')}
                >
                  School
                </TableSortLabel>
              </TableCell>
              <TableCell>Items</TableCell>
              {hasHighSchools && (
                <>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'subTotalAmount'}
                      direction={
                        orderBy === 'subTotalAmount' ? sortOrder : 'asc'
                      }
                      onClick={() => handleRequestSort('subTotalAmount')}
                    >
                      Sub Total Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'surchargeTotal'}
                      direction={
                        orderBy === 'surchargeTotal' ? sortOrder : 'asc'
                      }
                      onClick={() => handleRequestSort('surchargeTotal')}
                    >
                      Surcharge
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'totalAmount'}
                      direction={orderBy === 'totalAmount' ? sortOrder : 'asc'}
                      onClick={() => handleRequestSort('totalAmount')}
                    >
                      Total Amount
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'deliveryDate'}
                  direction={orderBy === 'deliveryDate' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('deliveryDate')}
                >
                  Delivery Date
                </TableSortLabel>
              </TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((o) => (
              <TableRow key={o._id}>
                <TableCell>
                  {new Date(o.orderDate).toLocaleDateString()}
                </TableCell>
                <TableCell>{new Date(o.weekOf).toLocaleDateString()}</TableCell>
                <TableCell>{o.school?.name}</TableCell>
                <TableCell>
                  <>
                    {(() => {
                      const { premade, other } = o.items.reduce(
                        (acc, item) =>
                          item.menuItem?.category === 'Pre-Made'
                            ? { ...acc, premade: acc.premade + 1 }
                            : { ...acc, other: acc.other + 1 },
                        { premade: 0, other: 0 },
                      );
                      return `Premade: ${premade}, Bulk: ${other}`;
                    })()}
                  </>
                </TableCell>
                {hasHighSchools && (
                  <>
                    <TableCell>
                      {o.school.schoolType !== 'Elementary'
                        ? `$${o.subTotalAmount.toFixed(2)}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {o.school.schoolType !== 'Elementary'
                        ? `$${o.surchargeTotal.toFixed(2)}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {o.school.schoolType !== 'Elementary'
                        ? `$${o.totalAmount.toFixed(2)}`
                        : 'N/A'}
                    </TableCell>
                  </>
                )}
                <TableCell>{o.status}</TableCell>
                <TableCell>
                  {o.deliveryDate
                    ? new Date(o.deliveryDate).toLocaleDateString()
                    : 'N/A'}
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    edge='end'
                    aria-label='print'
                    onClick={() => onPrint(o)}
                  >
                    <PrintIcon />
                  </IconButton>
                  {o.status === 'New' || o.status === 'Modified' ? (
                    <>
                      <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => onEdit(o)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        color='secondary'
                        onClick={() => handleDeleteConfirmation(o._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Typography variant='caption'>N/A</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title='Delete Order'
        message={`Are you sure you want to delete the order?`}
      />
    </div>
  );
};

export default FoodOrderList;
