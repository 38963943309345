import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuItemsReport } from '../../features/reporting';
import {
  Typography,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import MenuItemsChart from './Charts/MenuItemsChart'; // Import the chart component
import PeriodSelector from './PeriodSelector'; // Adjust the import path as needed
import { getDateRange } from '../../utils/dateUtils';

const MenuItemsReport = () => {
  const dispatch = useDispatch();
  const { menuItemsReport, status, error } = useSelector(
    (state) => state.reporting,
  );

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showAll, setShowAll] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [period, setPeriod] = useState('schoolYear');

  // Set initial date range and trigger data fetching
  useEffect(() => {
    if (period !== 'custom') {
      const { start, end } = getDateRange(period);
      setStartDate(start.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    }
  }, [period]); // Trigger when period changes

  useEffect(() => {
    if (startDate && endDate)
      dispatch(fetchMenuItemsReport({ startDate, endDate, showAll }));
  }, [dispatch, startDate, endDate, showAll]);

  const sortData = (data) => {
    if (!data) return [];

    if (sortBy === 'name') {
      return data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return sortOrder === 'asc' ? -1 : 1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    if (sortBy === 'quantity') {
      return data.sort(
        (a, b) => (a.quantity - b.quantity) * (sortOrder === 'asc' ? 1 : -1),
      );
    }

    return data;
  };

  const sumMenuItems = (data) => {
    return data.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.name === item.name);
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, []);
  };

  const filteredData =
    Array.isArray(menuItemsReport) && status === 'succeeded'
      ? menuItemsReport.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  const summedData = Array.isArray(filteredData)
    ? sumMenuItems(filteredData)
    : [];
  const sortedData = Array.isArray(summedData) ? sortData(summedData) : [];

  // Calculate total number of items and sum of quantities
  const totalItems = summedData.length;
  const totalQuantity = summedData.reduce(
    (total, item) => total + item.quantity,
    0,
  );

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setPeriod(value);

    // if value = custom then show the start and end date fields
    if (value === 'custom') {
      setShowAll(false);
      setShowCustom(true);
      return;
    } else {
      setShowCustom(false);
    }

    const { start, end } = getDateRange(value);

    if (value === 'all') {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);
  };

  return (
    <div>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant='h4' gutterBottom align='center'>
          Menu Items Report
        </Typography>
        <PeriodSelector
          period={period}
          handlePeriodChange={handlePeriodChange}
        />
        <Box mb={2} display='flex'>
          {showCustom && (
            <>
              <TextField
                type='date'
                label='Start Date'
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                type='date'
                label='End Date'
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </>
          )}
        </Box>
        <TextField
          label='Search by Item'
          variant='outlined'
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {status === 'loading' && <CircularProgress />}
        {status === 'failed' && <Alert severity='error'>{error}</Alert>}

        {status === 'succeeded' && Array.isArray(menuItemsReport) && (
          <>
            {/* Summary Info */}
            <Box mb={2} display='flex' justifyContent='space-between'>
              <Typography variant='h6'>Total Items: {totalItems}</Typography>
              <Typography variant='h6'>
                Total Quantity: {totalQuantity}
              </Typography>
            </Box>

            {/* Render the chart component */}
            <MenuItemsChart data={sortedData} />

            {/* Render the table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => handleSort('name')}
                      style={{ cursor: 'pointer' }}
                    >
                      Item Name{' '}
                      {sortBy === 'name' &&
                        (sortOrder === 'asc' ? (
                          <ArrowUpward />
                        ) : (
                          <ArrowDownward />
                        ))}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('quantity')}
                      style={{ cursor: 'pointer' }}
                    >
                      Quantity{' '}
                      {sortBy === 'quantity' &&
                        (sortOrder === 'asc' ? (
                          <ArrowUpward />
                        ) : (
                          <ArrowDownward />
                        ))}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('size')}
                      style={{ cursor: 'pointer' }}
                    >
                      Size{' '}
                      {sortBy === 'size' &&
                        (sortOrder === 'asc' ? (
                          <ArrowUpward />
                        ) : (
                          <ArrowDownward />
                        ))}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.size}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </div>
  );
};

export default MenuItemsReport;
