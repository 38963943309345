import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoricActivityReport } from '../../features/reporting';
import { getDateRange } from '../../utils/dateUtils';
import {
  Typography,
  Box,
  Button,
  TextField,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TableFooter,
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import SchoolDetailDialog from './SchoolDetailDialog';
import SchoolOrdersChart from './Charts/SchoolOrdersChart';
import PeriodSelector from './PeriodSelector'; // Adjust the import path as needed

const HistoricActivityReport = () => {
  const dispatch = useDispatch();
  const { historicActivityReport, status, error } = useSelector(
    (state) => state.reporting,
  );

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [period, setPeriod] = useState('schoolYear');
  const [sortBy, setSortBy] = useState('school');
  const [sortOrder, setSortOrder] = useState('asc');
  const [search, setSearch] = useState('');
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showGraph, setShowGraph] = useState(true);

  // Set initial date range and trigger data fetching
  useEffect(() => {
    if (period !== 'custom') {
      const { start, end } = getDateRange(period);
      setStartDate(start.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    }
  }, [period]); // Trigger when period changes

  useEffect(() => {
    if (startDate && endDate)
      dispatch(fetchHistoricActivityReport({ startDate, endDate, showAll }));
  }, [dispatch, startDate, endDate, showAll]);

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setPeriod(value);

    // if value = custom then show the start and end date fields
    if (value === 'custom') {
      setShowAll(false);
      setShowCustom(true);
      return;
    } else {
      setShowCustom(false);
    }

    const { start, end } = getDateRange(value);

    if (value === 'all') {
      setShowAll(true);
    } else {
      setShowAll(false);
    }

    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);
  };

  const groupBySchool = (data) => {
    if (!Array.isArray(data)) return [];

    const grouped = data.reduce((acc, entry) => {
      const schoolName = entry.school?.name || 'Unknown School';
      if (!acc[schoolName]) {
        acc[schoolName] = {
          school: schoolName,
          totalAmount: 0,
          totalItems: 0,
          totalOrders: 0,
          orders: [],
          itemsSummary: {},
        };
      }

      const itemCount = entry.items.reduce(
        (sum, item) => sum + item.quantity,
        0,
      );
      acc[schoolName].totalAmount += entry.totalAmount;
      acc[schoolName].totalItems += itemCount;
      acc[schoolName].totalOrders += 1;

      entry.items.forEach((item) => {
        if (!acc[schoolName].itemsSummary[item.name]) {
          acc[schoolName].itemsSummary[item.name] = 0;
        }
        acc[schoolName].itemsSummary[item.name] += item.quantity;
      });

      acc[schoolName].orders.push({
        id: entry._id,
        amount: entry.totalAmount,
        orderDate: entry.weekOf,
        quantity: itemCount,
        items: entry.items.map((item) => ({
          name: item.menuItem.name,
          price: item.price,
          quantity: item.quantity,
        })),
      });

      return acc;
    }, {});

    return Object.values(grouped);
  };

  const calculateTotals = (data) => {
    const totalOrders = data.reduce(
      (sum, school) => sum + school.totalOrders,
      0,
    );
    const totalItems = data.reduce((sum, school) => sum + school.totalItems, 0);
    const totalAmount = data.reduce(
      (sum, school) => sum + school.totalAmount,
      0,
    );

    return { totalOrders, totalItems, totalAmount };
  };

  const sortData = (data) => {
    if (sortBy === 'school') {
      return data.sort((a, b) => {
        if (a.school.toLowerCase() < b.school.toLowerCase())
          return sortOrder === 'asc' ? -1 : 1;
        if (a.school.toLowerCase() > b.school.toLowerCase())
          return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
    if (sortBy === 'totalOrders') {
      return data.sort(
        (a, b) =>
          (a.totalOrders - b.totalOrders) * (sortOrder === 'asc' ? 1 : -1),
      );
    }
    if (sortBy === 'totalItems') {
      return data.sort(
        (a, b) =>
          (a.totalItems - b.totalItems) * (sortOrder === 'asc' ? 1 : -1),
      );
    }
    if (sortBy === 'totalAmount') {
      return data.sort(
        (a, b) =>
          (a.totalAmount - b.totalAmount) * (sortOrder === 'asc' ? 1 : -1),
      );
    }
    return data;
  };
  const filteredData = groupBySchool(historicActivityReport || [])
    .filter((group) => {
      const matchesSearch = group.school
        .toLowerCase()
        .includes(search.toLowerCase());
      return matchesSearch;
    })
    .filter((group) => {
      const hasValidOrder = group.orders.some((order) => {
        const orderDate = new Date(order.orderDate);
        const isWithinRange =
          orderDate >= new Date(startDate) && orderDate <= new Date(endDate);

        return isWithinRange;
      });
      return hasValidOrder;
    });

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleOpenDialog = (school) => {
    setSelectedSchool(school);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedSchool(null);
    setDialogOpen(false);
  };

  const handleToggleGraph = () => {
    setShowGraph(!showGraph);
  };

  const sortedData = sortData(filteredData);
  const totals = calculateTotals(sortedData);

  return (
    <div>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant='h4' gutterBottom align='center'>
          Historic Activity Report
        </Typography>
        <Box mb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleToggleGraph}>
            {showGraph ? 'Hide Graph' : 'Show Graph'}
          </Button>
        </Box>
        {showGraph && (
          <Box mb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <SchoolOrdersChart data={sortedData} />
          </Box>
        )}

        {/* Summary Section */}
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <Box mb={4}>
            <Typography variant='h5' align='center' gutterBottom>
              Summary
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant='subtitle1' align='center'>
                  Total Orders
                </Typography>
                <Typography variant='h6' align='center'>
                  {totals.totalOrders.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant='subtitle1' align='center'>
                  Total Items
                </Typography>
                <Typography variant='h6' align='center'>
                  {totals.totalItems.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant='subtitle1' align='center'>
                  Total Amount Spent
                </Typography>
                <Typography variant='h6' align='center'>
                  $
                  {totals.totalAmount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Box mb={2} display='flex' gap={2} alignItems='center'>
          {showCustom && (
            <>
              <TextField
                type='date'
                label='Start Date'
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                type='date'
                label='End Date'
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </>
          )}
          <PeriodSelector
            period={period}
            handlePeriodChange={handlePeriodChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <TextField
            label='Search by School'
            variant='outlined'
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>

        {status === 'loading' && <CircularProgress />}
        {status === 'failed' && <Alert severity='error'>{error}</Alert>}

        {status === 'succeeded' && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => handleSort('school')}
                    style={{ cursor: 'pointer' }}
                  >
                    School{' '}
                    {sortBy === 'school' &&
                      (sortOrder === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      ))}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('totalOrders')}
                    style={{ cursor: 'pointer' }}
                  >
                    Total Orders{' '}
                    {sortBy === 'totalOrders' &&
                      (sortOrder === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      ))}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('totalItems')}
                    style={{ cursor: 'pointer' }}
                  >
                    Total Items{' '}
                    {sortBy === 'totalItems' &&
                      (sortOrder === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      ))}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('totalAmount')}
                    style={{ cursor: 'pointer' }}
                  >
                    Total Amount Spent{' '}
                    {sortBy === 'totalAmount' &&
                      (sortOrder === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      ))}
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row) => (
                  <TableRow key={row.school}>
                    <TableCell>{row.school}</TableCell>
                    <TableCell>{row.totalOrders.toLocaleString()}</TableCell>
                    <TableCell>{row.totalItems.toLocaleString()}</TableCell>
                    <TableCell>${row.totalAmount.toLocaleString()}</TableCell>
                    <TableCell>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenDialog(row)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <strong>Totals:</strong>
                  </TableCell>
                  <TableCell>{totals.totalOrders.toLocaleString()}</TableCell>
                  <TableCell>{totals.totalItems.toLocaleString()}</TableCell>
                  <TableCell>${totals.totalAmount.toLocaleString()}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}

        {selectedSchool && (
          <SchoolDetailDialog
            schoolDetails={selectedSchool}
            open={dialogOpen}
            onClose={handleCloseDialog}
          />
        )}
      </Paper>
    </div>
  );
};

export default HistoricActivityReport;
