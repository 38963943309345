import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Autocomplete,
  IconButton,
  Alert,
  Stack,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import ImageUploader from '../Images/ImageUpload';
const options = ['', 'Main', 'Fruit/Veg', 'Snack'];
const menuOptions = ['', 'Breakfast', 'Lunch', 'Snack'];

const MenuItemForm = ({
  open,
  handleClose,
  selectedItem = null,
  handleSave,
  categories = [], // Default to empty array
  items = [], // Default to empty array
  resetForm,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [active, setActive] = useState(true);
  const [size, setSize] = useState('');
  const [units, setUnits] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [isCollection, setIsCollection] = useState(false);
  const [preMadeMenuType, setPreMadeMenuType] = useState([]);
  const [preMadeMenuOptions, setPreMadeMenuOptions] = useState([]);
  const [collectionItems, setCollectionItems] = useState([
    { _id: '', name: '', quantity: '', units: '' },
  ]);

  const [alerts, setAlerts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [warning, setWarning] = useState('');

  // Convert unitsList to array
  const unitsList = Array.from(
    new Set([
      'oz',
      'lb',
      'each',
      'piece',
      'g',
      'kg',
      'slice',
      'cup',
      'tbsp',
      'tsp',
      'package',
      'sleeve',
      'head',
      'loaf',
      'box',
      'unit',
    ]),
  );

  // Manage local categories
  const [localCategories, setLocalCategories] = useState(categories);

  useEffect(() => {}, [collectionItems]);

  useEffect(() => {
    if (selectedItem) {
      setName(selectedItem.name || '');
      setSize(selectedItem.size || '');
      setUnits(selectedItem.units || '');
      setDescription(selectedItem.description || '');
      setPrice(selectedItem.price || '');
      setCategory(selectedItem.category || '');
      setActive(selectedItem.active ?? true);
      setIsCollection(selectedItem.isCollection || false);
      setPreMadeMenuType(selectedItem.preMadeMenuType || []);
      setPreMadeMenuOptions(selectedItem.preMadeMenuOptions || []);
      setCollectionItems(
        selectedItem.collectionItems || [
          { _id: '', name: '', quantity: '', units: '' },
        ],
      );
    } else {
      handleResetForm();
    }
  }, [selectedItem]);

  const handleResetForm = () => {
    setName('');
    setDescription('');
    setSize('');
    setUnits('each');
    setPrice('');
    setCategory('');
    setActive(true);
    setIsCollection(false);
    setCollectionItems([{ _id: '', name: '', quantity: '', units: '' }]);
    setAlerts([]);
  };

  const validateCollectionItems = () => {
    const newAlerts = [];
    collectionItems.forEach((item, index) => {
      if (!item.menuItem || !item.quantity || !item.units) {
        newAlerts.push({
          severity: 'error',
          message: `All fields are required for collection item ${index + 1}.`,
        });
      }
    });
    setAlerts(newAlerts);
    return newAlerts.length === 0;
  };

  const handleSubmit = () => {
    if (!category) {
      setAlerts([
        { severity: 'error', message: 'Please select or enter a category.' },
      ]);
      return;
    }

    if (isCollection && !validateCollectionItems()) {
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('size', size);
    formData.append('units', units);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('active', active);
    formData.append('isCollection', isCollection);
    formData.append('preMadeMenuType', JSON.stringify(preMadeMenuType));
    formData.append('preMadeMenuOptions', JSON.stringify(preMadeMenuOptions));

    if (
      collectionItems &&
      collectionItems.length > 0 &&
      collectionItems[0]._id && // Checks if _id exists and is not empty
      collectionItems[0]._id.trim() !== '' // Ensures _id is not just empty spaces
    ) {
      formData.append('collectionItems', JSON.stringify(collectionItems));
    }

    if (selectedItem) {
      formData.append('_id', selectedItem._id);
    }

    if (!selectedFile && selectedItem !== null) {
      formData.append('imageInfo', JSON.stringify(selectedItem.imageInfo));
    } else if (selectedFile) {
      formData.append('image', selectedFile);
    }

    //  show formData in console
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    handleSave(formData);
    handleResetForm();
  };

  const handleAutoCompleteChange = (event, value) => {
    setCategory(value);
    setWarning(''); // Clear warning when a valid category is selected
  };

  const handleAutoCompleteInputChange = (event, value) => {
    setNewCategory(value);
    if (value && !localCategories.includes(value)) {
      setWarning(`"${value}" will be created as a new category.`);
    } else {
      setWarning('');
    }
  };
  const handleCollectionItemChange = (index, key, value, _id) => {
    const newCollectionItems = collectionItems.map((item, i) =>
      i === index ? { ...item, [key]: value } : item,
    );

    if (key === 'name') {
      const selectedMenuItem = items.find(
        (item) => item.name === value.name && item.category === value.category,
      );
      newCollectionItems[index] = {
        ...newCollectionItems[index],
        menuItem: {
          ...newCollectionItems[index].menuItem,
          _id: selectedMenuItem?._id || '',
        },
      };
    }

    setCollectionItems(newCollectionItems);
  };

  const handleAddCollectionItem = () => {
    setCollectionItems([
      ...collectionItems,
      { _id: '', name: '', quantity: '', units: '' },
    ]);
  };

  const handleRemoveCollectionItem = (index) => {
    const newCollectionItems = collectionItems.filter((_, i) => i !== index);
    setCollectionItems(newCollectionItems);
  };

  // Reset form when resetForm prop changes
  useEffect(() => {
    if (resetForm) {
      handleResetForm();
    }
  }, [resetForm]);

  // Update local categories
  useEffect(() => {
    if (newCategory && !localCategories.includes(newCategory)) {
      setLocalCategories([...localCategories, newCategory]);
    }
  }, [newCategory, localCategories]);

  // lets make a subset of collectionItems where cateogry is ingredients and call it collectionItemsIngredients
  const ingredientsItems = items.filter(
    (item) => item.category === 'Ingredients',
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>
        {selectedItem ? 'Update Menu Item' : 'Add New Menu Item'}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mb={2}>
          {alerts.map((alert, index) => (
            <Alert key={index} severity={alert.severity}>
              {alert.message}
            </Alert>
          ))}
        </Stack>
        <TextField
          autoFocus
          margin='dense'
          label='Name'
          type='text'
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin='dense'
          label='Size'
          type='text'
          fullWidth
          style={{ marginBottom: '10px' }}
          value={size}
          onChange={(e) => setSize(e.target.value)}
        />
        <Autocomplete
          options={unitsList}
          margin='dense'
          value={units}
          style={{ marginBottom: '5px' }}
          onChange={(e, value) => setUnits(value)}
          renderInput={(params) => <TextField {...params} label='Units' />}
        />
        <div>
          <Autocomplete
            options={localCategories}
            margin='dense'
            value={category}
            onChange={handleAutoCompleteChange}
            inputValue={newCategory}
            freeSolo
            onInputChange={handleAutoCompleteInputChange}
            renderInput={(params) => (
              <TextField {...params} label='Category of Item' />
            )}
          />
          {warning && <Alert severity='warning'>{warning}</Alert>}
        </div>
        <TextField
          margin='dense'
          label='Price'
          type='number'
          fullWidth
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <TextField
          margin='dense'
          label='Description'
          type='text'
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={() => setActive((prev) => !prev)}
            />
          }
          label='Active'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCollection}
              onChange={() => setIsCollection((prev) => !prev)}
            />
          }
          label='Is Collection'
        />
        <FormControl sx={{ mt: '10px' }} fullWidth>
          <InputLabel id='select-label'>PreMade Meal Type</InputLabel>
          <Select
            labelId='select-label'
            multiple
            label='PreMade Meal Type'
            sx={{ mb: '10px' }}
            value={preMadeMenuType}
            onChange={(e) => setPreMadeMenuType(e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: '10px' }} fullWidth>
          <InputLabel id='preMadeMealTimes-label'>
            PreMade Meal Times
          </InputLabel>
          <Select
            labelId='preMadeMealTimes-label'
            label='PreMade Meal Times'
            multiple
            sx={{ mb: '10px' }}
            value={preMadeMenuOptions}
            onChange={(e) => setPreMadeMenuOptions(e.target.value)}
          >
            {menuOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isCollection && (
          <Box>
            <Typography variant='h6'>Collection Items</Typography>
            {collectionItems.map((item, index) => (
              <Box
                key={index}
                display='flex'
                gap={1}
                mb={1}
                alignItems='center'
              >
                <Autocomplete
                  //this needs to show both the name and the category
                  value={item.menuItem}
                  fullWidth
                  required
                  onChange={(event, value) => {
                    const selectedMenuItem = items.find(
                      (itm) => `${itm.name} (${itm.category})` === value,
                    );
                    handleCollectionItemChange(
                      index,
                      'name',
                      value,
                      selectedMenuItem?._id || '',
                    );
                  }}
                  options={ingredientsItems.map((item) => ({
                    name: item.name,
                    category: item.category,
                  }))}
                  getOptionLabel={(option) => `${option.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label='Item Name' fullWidth />
                  )}
                  error={
                    !!alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )
                  }
                  helperText={
                    alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )?.message
                  }
                />
                <TextField
                  label='Quantity'
                  required
                  inputProps={{ min: 0 }}
                  type='number'
                  value={item.quantity}
                  onChange={(e) =>
                    handleCollectionItemChange(
                      index,
                      'quantity',
                      e.target.value,
                      item._id,
                    )
                  }
                  error={
                    !!alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )
                  }
                  helperText={
                    alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )?.message
                  }
                />
                <Autocomplete
                  options={unitsList}
                  required
                  value={item.units}
                  style={{ width: '250px' }}
                  onChange={(e, value) =>
                    handleCollectionItemChange(index, 'units', value, item._id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label='Units' />
                  )}
                  error={
                    !!alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )
                  }
                  helperText={
                    alerts.find((alert) =>
                      alert.message.includes(`collection item ${index + 1}`),
                    )?.message
                  }
                />
                <IconButton
                  color='secondary'
                  onClick={() => handleRemoveCollectionItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddCollectionItem}>
              Add Item
            </Button>
          </Box>
        )}

        <ImageUploader
          onFileSelect={setSelectedFile}
          initialImageURL={selectedItem?.imageInfo?.url}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuItemForm;
