import React, { useState, useEffect } from 'react';
import {
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import StepNavigationButtons from './StepNavigationButtons'; // Adjust the path as necessary
import { updateSchool } from '../../features/schools'; // Adjust the path as necessary

const SchoolContactStep = ({
  school,
  setActiveStep,
  contactName,
  setContactName,
  contactEmail,
  setContactEmail,
  contactPhone,
  setContactPhone,
}) => {
  const [useAssignedContact, setUseAssignedContact] = useState(false);
  const [updateSchoolContact, setUpdateSchoolContact] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const hasAssignedContact = Boolean(school.contact);

  // Format phone number for display (e.g., "(123) 456-7890")
  const formatPhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
    if (match) {
      const part1 = match[1] ? `(${match[1]}` : '';
      const part2 = match[2] ? `) ${match[2]}` : '';
      const part3 = match[3] ? `-${match[3]}` : '';
      return `${part1}${part2}${part3}`;
    }
    return phone; // If no match, return raw phone
  };
  // Clean phone number for storage (digits only)
  const cleanPhoneNumber = (phone) => {
    return phone.replace(/\D/g, ''); // Strip all non-numeric characters
  };

  // Validate the form fields
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Contact Name Validation
    if (!contactName.trim()) {
      newErrors.contactName = 'Contact Name is required';
      valid = false;
    }

    // Contact Email Validation
    if (
      contactEmail !== 'Imported' &&
      (!contactEmail.trim() || !/\S+@\S+\.\S+/.test(contactEmail))
    ) {
      newErrors.contactEmail = 'Invalid email address';
      valid = false;
    }

    // Contact Phone Validation
    const cleanedPhone = cleanPhoneNumber(contactPhone); // Clean the phone number for validation
    if (
      contactEmail !== 'Imported' &&
      (cleanedPhone.length < 10 || cleanedPhone.length > 11)
    ) {
      newErrors.contactPhone = 'Enter a valid 10 or 11-digit phone number';
      valid = false;
    }

    setErrors(newErrors);
    setErrorMessage(valid ? '' : Object.values(newErrors).join(' '));
    return valid;
  };

  // UseEffect to trigger validation after state changes (e.g., after autocomplete)
  useEffect(() => {
    if (contactPhone) {
      validateForm(); // Validate phone number once it is updated, including by autocomplete
    }
  }, [contactPhone]); // Trigger validation whenever `contactPhone` changes

  const handleFieldChange = (field, value) => {
    const trimmedValue = value.trim(); // Trim spaces from input
    if (field === 'contactName') setContactName(trimmedValue);
    if (field === 'contactEmail') setContactEmail(trimmedValue);
    if (field === 'contactPhone')
      setContactPhone(formatPhoneNumber(trimmedValue));
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1); // Navigate to the previous step
  };

  const handleNextStep = () => {
    if (validateForm()) {
      if (updateSchoolContact) {
        // Dispatch action to update school contact info
        dispatch(
          updateSchool({
            id: school._id,
            details: {
              ...school, // Spread existing school data
              contactName: contactName.trim(), // Update contact details
              contactEmail,
              contactPhone: cleanPhoneNumber(contactPhone), // Clean phone number for storage
            },
          }),
        );
      }
      setActiveStep((prevStep) => prevStep + 1); // Navigate to the next step if the form is valid
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Display the selected school */}
      <Typography variant='h6' gutterBottom>
        Order for {school?.name}
      </Typography>
      <Typography variant='subtitle2'>
        Enter Contact Information for this order
      </Typography>
      <FormControl fullWidth margin='normal'>
        {/* Show checkbox only if there's an assigned contact */}
        {hasAssignedContact && (
          <FormControlLabel
            control={
              <Checkbox
                checked={useAssignedContact}
                onChange={(e) => setUseAssignedContact(e.target.checked)}
              />
            }
            label='Use Assigned Contact'
          />
        )}

        {/* Form fields for contact details */}
        {!useAssignedContact && (
          <>
            <TextField
              label='Contact Name'
              value={contactName}
              onChange={(e) => handleFieldChange('contactName', e.target.value)}
              margin='normal'
              fullWidth
              error={!!errors.contactName}
              helperText={errors.contactName}
            />
            <TextField
              label='Contact Email'
              value={contactEmail}
              onChange={(e) =>
                handleFieldChange('contactEmail', e.target.value)
              }
              margin='normal'
              fullWidth
              type='email'
              error={!!errors.contactEmail}
              helperText={errors.contactEmail}
            />
            <TextField
              label='Contact Phone'
              value={contactPhone}
              onChange={(e) =>
                handleFieldChange('contactPhone', e.target.value)
              }
              onBlur={() => validateForm()} // Validate onBlur as well to handle autocomplete
              margin='normal'
              fullWidth
              type='tel'
              error={!!errors.contactPhone}
              helperText={errors.contactPhone}
            />
          </>
        )}

        {/* Checkbox to update school contact with new entry */}
        {!useAssignedContact && (
          <FormControlLabel
            control={
              <Checkbox
                checked={updateSchoolContact}
                onChange={(e) => setUpdateSchoolContact(e.target.checked)}
              />
            }
            label='Update school contact with new entry'
          />
        )}
      </FormControl>

      <StepNavigationButtons
        previousStep={handlePreviousStep}
        nextStep={handleNextStep}
        nextDisabled={!!errorMessage} // Disable "Next" button if there are validation errors
        nextText={errorMessage || 'Next Step - Order Week Selection'}
      />
    </Box>
  );
};

export default SchoolContactStep;
