import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import StepNavigationButtons from './StepNavigationButtons';

const OrderSummaryStep = ({
  school,
  weekOf,
  deliveryDate,
  status,
  subTotalAmount,
  items,
  handleSubmit,
  setActiveStep,
  isEdit,
  showPrices,
  surcharge,
}) => {
  const formattedWeekofDate = new Date(weekOf).toLocaleDateString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const handlePreviousStep = () => {
    // Navigate to the "Menu Items" step
    setActiveStep(3); // Set this to the step index of the "Menu Items" step
  };

  // Function to get the daily quantities for premade items
  const getDailyQuantities = (item) => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    return daysOfWeek.map((day) => item[`${day.toLowerCase()}Quantity`] || 0);
  };

  // Function to calculate the total quantity for premade items
  const calculatePremadeTotalQuantity = (item) => {
    return getDailyQuantities(item).reduce(
      (total, quantity) => total + quantity,
      0,
    );
  };

  // Separate items into Pre-Made and others
  const premadeItems = items.filter((item) => item.category === 'Pre-Made');
  const otherItems = items.filter((item) => item.category !== 'Pre-Made');

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
        <strong> Order Summary</strong>
      </Typography>
      <Box sx={{ ml: 3 }}>
        <Typography variant='body1' sx={{ mt: 1 }}>
          <strong> School:</strong> {school?.name}
        </Typography>
        <Typography variant='body1'>
          <strong>Week Of:</strong> {formattedWeekofDate}
        </Typography>
        <Typography variant='body1'>
          <strong>Delivery Date:</strong>
          {new Date(deliveryDate).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Typography>
        <Typography variant='body1'>
          <strong>Status:</strong> {status}
        </Typography>
        {showPrices && (
          <>
            <Typography variant='body1' sx={{ mt: 1 }}>
              <strong>Sub Total:</strong> ${subTotalAmount.toFixed(2)}
            </Typography>
            <Typography variant='body1' sx={{ mt: 0 }}>
              <strong>Coordination/Preparation/Delivery Charge:</strong> $
              {((subTotalAmount * surcharge) / 100).toFixed(2)}
            </Typography>
            <Typography variant='body1' sx={{ mt: 0 }}>
              <strong>Total Amount:</strong> $
              {(subTotalAmount + (subTotalAmount * surcharge) / 100).toFixed(2)}
            </Typography>
          </>
        )}
      </Box>
      <TableContainer
        component={Paper}
        sx={{ mt: 2, borderRadius: 2, boxShadow: 3 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
              >
                Item
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
              >
                Category
              </TableCell>
              {premadeItems.length > 0 && (
                <>
                  <TableCell
                    sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                  >
                    Monday
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                  >
                    Tuesday
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                  >
                    Wednesday
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                  >
                    Thursday
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                  >
                    Friday
                  </TableCell>
                </>
              )}
              <TableCell
                sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
              >
                Total Quantity
              </TableCell>
              {showPrices && (
                <TableCell
                  sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
                >
                  Total Cost
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Display Pre-Made items first */}
            {premadeItems.length > 0 && (
              <>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ fontWeight: 'bold', backgroundColor: '#d9e8f5' }}
                  >
                    Pre-Made Items
                  </TableCell>
                </TableRow>
                {premadeItems.map((item) => (
                  <TableRow key={item.menuItem}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    {getDailyQuantities(item).map((quantity, index) => (
                      <TableCell key={index}>{quantity}</TableCell>
                    ))}
                    <TableCell>{calculatePremadeTotalQuantity(item)}</TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* Display other items */}
            {otherItems.length > 0 && (
              <>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ fontWeight: 'bold', backgroundColor: '#d9e8f5' }}
                  >
                    Other Items
                  </TableCell>
                </TableRow>
                {otherItems.map((item) => (
                  <TableRow key={item.menuItem}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    {showPrices && (
                      <TableCell>
                        ${(item.quantity * item.price).toFixed(2)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <StepNavigationButtons previousStep={handlePreviousStep} />
      <Button
        onClick={handleSubmit}
        variant='contained'
        color='primary'
        sx={{ mt: 2 }}
      >
        {isEdit ? 'Update Order' : 'Submit Order'}
      </Button>
    </Box>
  );
};

export default OrderSummaryStep;
