import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  CardMedia,
  TextField,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import NoImage from '../../assets/images/LTExp.webp'; // Adjust the path if necessary
const MenuItemCard = ({
  menuItem,
  quantity,
  onIncrease,
  onDecrease,
  onSetQuantity,
  showPrices,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [inputQuantity, setInputQuantity] = useState(quantity);
  const textFieldRef = useRef(null);

  // Effect for syncing input quantity with prop quantity
  useEffect(() => {
    setInputQuantity(quantity);
  }, [quantity]);

  // Effect for focusing the input field when edit mode is enabled
  useEffect(() => {
    if (editMode && textFieldRef.current) {
      const input = textFieldRef.current.querySelector('input');
      if (input) {
        input.focus();
        input.select();
      }
    }
  }, [editMode]);

  // Handler for changing quantity input
  const handleQuantityChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setInputQuantity(Number(value));
    }
  };

  // Handler for blurring the quantity input
  const handleQuantityBlur = () => {
    if (inputQuantity >= 0) {
      onSetQuantity(menuItem._id, inputQuantity);
    }
    setEditMode(false);
  };

  // Handler for pressing the Enter key in the quantity input
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleQuantityBlur();
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 200,
        minHeight: 'auto',
        width: '200px',
        bgcolor: 'white',
        raised: true,
        mb: 1,
        pt: 2,
        px: 1,
        mx: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      elevation={4}
    >
      <CardMedia
        component='img'
        height='140'
        image={menuItem.imageInfo.url || NoImage}
        alt={menuItem.name}
        sx={{
          bgcolor: 'white',
          objectFit: 'contain',
          width: '100%',
          height: '140px',
        }}
      />
      <CardContent sx={{ flexGrow: 1, p: 1 }}>
        <Typography variant='h6' noWrap>
          {menuItem.name}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          Size: {menuItem.size} {menuItem.unit}
        </Typography>
        {showPrices && (
          <Typography variant='body2' color='textSecondary'>
            ${menuItem.price.toFixed(2)}
          </Typography>
        )}
        <Box
          display='flex'
          alignItems='center'
          mt={1}
          justifyContent='space-between'
        >
          <IconButton onClick={onDecrease} disabled={quantity === 0}>
            <Remove />
          </IconButton>
          {editMode ? (
            <TextField
              value={inputQuantity}
              onChange={handleQuantityChange}
              onBlur={handleQuantityBlur}
              onKeyDown={handleKeyDown}
              type='number'
              size='small'
              inputProps={{ min: 0 }}
              sx={{ width: 80, textAlign: 'center' }}
              ref={textFieldRef}
            />
          ) : (
            <Typography
              variant='body1'
              mx={2}
              onClick={() => setEditMode(true)}
              sx={{ cursor: 'pointer' }}
            >
              {inputQuantity}
            </Typography>
          )}
          <IconButton onClick={onIncrease}>
            <Add />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MenuItemCard;
