// src/components/StatusModal.js

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StatusModal = ({ open, onClose, message }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant='h6' component='h2'>
          Registration Status
        </Typography>
        <Typography sx={{ mt: 2 }}>{message}</Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            window.location.href = '/login'; // Redirect to login page
          }}
          sx={{ mt: 2, ml: 2 }}
        >
          Go to Login
        </Button>
      </Box>
    </Modal>
  );
};

export default StatusModal;
