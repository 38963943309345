import React, { useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
} from '@mui/material';
import MenuItemCard from './MenuItemCard';
import PremadeSelections from './PremadeSelections';
import StepNavigationButtons from './StepNavigationButtons';

const MenuItemsStep = ({
  menuItems = [],
  items = [],
  setItems,
  subTotalAmount,
  setSubTotalAmount,
  setActiveStep,
  school,
  showPrices,
}) => {
  const [activeStep, updateActiveStep] = useState(0);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const categories = [...new Set(menuItems.map((item) => item.category))];

  const handleIncrease = (menuItemId) => {
    const updatedItems = [...items];
    const item = updatedItems.find((i) => i.menuItem === menuItemId);
    if (item) {
      item.quantity += 1;
    } else {
      const menuItem = menuItems.find((m) => m._id === menuItemId);
      updatedItems.push({
        menuItem: menuItemId,
        price: menuItem?.price || 0,
        name: menuItem?.name || '',
        category: menuItem?.category || '',
        quantity: 1,
      });
    }
    setItems(updatedItems);
    setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
  };

  const handleDecrease = (menuItemId) => {
    const updatedItems = [...items];
    const item = updatedItems.find((i) => i.menuItem === menuItemId);
    if (item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        updatedItems.splice(updatedItems.indexOf(item), 1);
      }
      setItems(updatedItems);
      setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
    }
  };

  const handleSetQualityAllDays = (
    menuItemId,
    updatedQuantity,
    totalQuantity,
  ) => {
    const updatedItems = [...items];
    const item = updatedItems.find((i) => i.menuItem === menuItemId);
    if (!item) {
      const menuItem = menuItems.find((m) => m._id === menuItemId);
      updatedItems.push({
        menuItem: menuItemId,
        name: menuItem?.name || '',
        price: menuItem?.price || 0,
        quantity: totalQuantity,
        category: menuItem?.category || '',
        ...updatedQuantity,
      });
    } else {
      Object.keys(updatedQuantity).forEach((key) => {
        item[key] = updatedQuantity[key];
      });
    }
    setItems(updatedItems);
    setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
  };

  const handleSetQuantity = (menuItemId, quantity) => {
    const updatedItems = items.map((item) => {
      if (item.menuItem === menuItemId) {
        return {
          ...item,
          quantity: quantity > 0 ? quantity : 0,
        };
      }
      return item;
    });
    if (!updatedItems.some((item) => item.menuItem === menuItemId)) {
      const menuItem = menuItems.find((m) => m._id === menuItemId);
      updatedItems.push({
        menuItem: menuItemId,
        name: menuItem?.name || '',
        price: menuItem?.price || 0,
        category: menuItem?.category || '',
        quantity: quantity,
      });
    }

    setItems(updatedItems);
    setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
  };

  const handleSetDayQuantity = (menuItemId, day, quantity) => {
    // Handle the case where day is not provided
    if (!day) {
      const updatedItems = items.map((item) => {
        if (item.menuItem === menuItemId) {
          return {
            ...item,
            price: item.price || 0,
            quantity: quantity > 0 ? quantity : 0,
          };
        }
        return item;
      });

      if (!updatedItems.some((item) => item.menuItem === menuItemId)) {
        const menuItem = menuItems.find((m) => m._id === menuItemId);
        updatedItems.push({
          menuItem: menuItemId,
          name: menuItem?.name || '',
          price: menuItem?.price || 0,
          category: menuItem?.category || '',
          quantity: quantity,
        });
      }

      setItems(updatedItems);
      setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
      return;
    }

    // Handle the case where day is provided
    const updatedItems = items.map((item) => {
      if (item.menuItem === menuItemId) {
        return {
          ...item,
          [`${day.replace('Quantity', '').toLowerCase()}Quantity`]:
            quantity > 0 ? quantity : 0,
        };
      }
      return item;
    });

    if (!updatedItems.some((item) => item.menuItem === menuItemId)) {
      const menuItem = menuItems.find((m) => m._id === menuItemId);
      updatedItems.push({
        menuItem: menuItemId,
        name: menuItem?.name || '',
        price: menuItem?.price || 0,
        category: menuItem?.category || '',
        [`${day.replace('Quantity', '').toLowerCase()}Quantity`]: quantity,
      });
    }
    setItems(updatedItems);
    setSubTotalAmount(calculateTotalAmount(updatedItems, menuItems));
  };

  const calculateTotalAmount = (items = [], menuItems = []) => {
    return items.reduce((sum, item) => {
      const menuItem = menuItems.find((m) => m._id === item.menuItem);
      if (!menuItem) return sum;

      const weeklyTotal = daysOfWeek.reduce((daySum, day) => {
        const dayQuantity = item[`${day.toLowerCase()}Quantity`] || 0;
        return daySum + menuItem.price * dayQuantity;
      }, 0);

      const itemTotal = menuItem.price * (item.quantity || 0);
      return sum + weeklyTotal + itemTotal;
    }, 0);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  // Sort categories with "Pre-Made" first, followed by the rest in alphabetical order
  const sortedCategories = categories
    .filter((category) => {
      // Filter out "Ingredients" and "Menu Item"
      if (category === 'Ingredients' || category === 'Menu Item') return false;
      // Filter out "Pre-Made" if school.schoolType is "High School"
      if (category === 'Pre-Made' && school.schoolType === 'High') return false;
      return true;
    })
    .sort((a, b) => {
      if (a === 'Pre-Made') return -1;
      if (b === 'Pre-Made') return 1;
      return a.localeCompare(b);
    });

  const currentCategory = sortedCategories[activeStep];

  const filteredMenuItems = menuItems
    .filter(
      (item) => currentCategory === 'All' || item.category === currentCategory,
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Paper elevation={3} sx={{ mt: 3 }}>
      <Box sx={{ pt: 1 }}>
        <Typography variant='h2' align='center' sx={{ pt: 0 }}>
          Menu Items
        </Typography>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant='subtitle1' align='center' sx={{ mb: 3 }}>
            Select Items from the different categories to build your order.
          </Typography>

          <Stepper activeStep={activeStep} alternativeLabel>
            {sortedCategories.map((category, index) => (
              <Step key={category}>
                <StepLabel
                  onClick={() => updateActiveStep(index)}
                  sx={{ cursor: 'pointer' }}
                >
                  {category}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ display: 'flex', flex: 1 }}>
          {activeStep > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '48px',
                height: '200px',
                pt: 2,
                position: 'relative',
              }}
            >
              <Button
                sx={{
                  'whiteSpace': 'nowrap',
                  'fontSize': '16px',
                  'position': 'absolute',
                  'top': '50%',
                  'left': '-75%',
                  'bgcolor': 'primary.main',
                  'color': 'white',
                  'padding': '8px',
                  'transform': 'rotate(-90deg) translate(-50%, -50%)',
                  'textAlign': 'center',
                  'transition': 'background-color 0.3s, color 0.3s',
                  '&:hover': {
                    bgcolor: 'white',
                    color: 'primary.main',
                  },
                }}
                onClick={() =>
                  updateActiveStep((prev) =>
                    Math.min(prev - 1, sortedCategories.length - 1),
                  )
                }
                aria-label='Previous'
              >
                Prior Food Category
              </Button>
            </Box>
          )}

          <Box sx={{ flex: 1, overflowY: 'auto', px: 2 }}>
            {showPrices && (
              <Typography
                variant='h6'
                sx={{ position: 'absolute', top: 16, right: 16 }}
              >
                Total Amount: ${subTotalAmount.toFixed(2)}
              </Typography>
            )}
            <Box display='flex' flexWrap='wrap' mt={2}>
              {currentCategory === 'Pre-Made' ? (
                <PremadeSelections
                  filteredMenuItems={filteredMenuItems}
                  items={items}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                  handleSetQualityAllDays={handleSetQualityAllDays}
                  handleSetQuantity={handleSetDayQuantity}
                />
              ) : (
                filteredMenuItems.map((item) => (
                  <MenuItemCard
                    key={item._id}
                    menuItem={item}
                    quantity={
                      items.find((i) => i.menuItem === item._id)?.quantity || 0
                    }
                    onIncrease={() => handleIncrease(item._id)}
                    onDecrease={() => handleDecrease(item._id)}
                    onSetQuantity={handleSetQuantity}
                    showPrices={showPrices}
                  />
                ))
              )}
            </Box>
          </Box>

          {activeStep < sortedCategories.length - 1 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '48px',
                height: '200px',
                pt: 2,
                position: 'relative',
              }}
            >
              <Button
                sx={{
                  'whiteSpace': 'nowrap',
                  'fontSize': '16px',
                  'position': 'absolute',
                  'top': '50%',
                  'left': '-65%',
                  'bgcolor': 'primary.main',
                  'color': 'white',
                  'padding': '8px',
                  'transform': 'rotate(-90deg) translate(-50%, -50%)',
                  'textAlign': 'center',
                  'transition': 'background-color 0.3s, color 0.3s',
                  '&:hover': {
                    bgcolor: 'white',
                    color: 'primary.main',
                  },
                }}
                onClick={() =>
                  updateActiveStep((prev) =>
                    Math.min(prev + 1, sortedCategories.length - 1),
                  )
                }
                aria-label='Next'
              >
                Next Food Category
              </Button>
            </Box>
          )}
        </Box>
        <StepNavigationButtons
          previousStep={handlePreviousStep}
          nextStep={handleNextStep}
          nextText='Complete Order'
        />
      </Box>
    </Paper>
  );
};

export default MenuItemsStep;
