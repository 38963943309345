// src/components/Register/Form.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  InputLabel,
  Button,
  Typography,
  Paper,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Chip,
  Link,
} from '@mui/material';
import { showToastError } from '../../utils/toastUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrganizationsPublic,
  selectOrganizations,
} from '../../features/organizations'; // Adjust path   as necessary
import {
  fetchSchoolsByOrganization,
  selectSchools,
} from '../../features/schools'; // Adjust path as necessary
import ImagePreview from '../Images/ImagePreview';
import ImageUploader from '../Images/ImageUpload';

const RegisterForm = ({ organization, onRegister }) => {
  const dispatch = useDispatch();
  const organizations = useSelector(selectOrganizations);
  const schools = useSelector(selectSchools);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [image, setImage] = useState(null); // State for the image
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchOrganizationsPublic(organization));
  }, [dispatch, organization]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(fetchSchoolsByOrganization(selectedOrganization));
    }
  }, [dispatch, selectedOrganization]);

  useEffect(() => {
    if (organization) {
      const org = organizations.find((org) => org._id === organization);
      if (org) {
        setSelectedOrganization(org._id);
      }
    }
  }, [organizations, organization]);

  const handleOrganizationChange = (e) => {
    const newOrganization = e.target.value;
    setSelectedOrganization(newOrganization);
    setSelectedSchools([]); // Clear selected schools when organization changes
  };

  const handleSchoolChange = (e) => {
    setSelectedSchools(e.target.value);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validatePassword = (password) => {
    // Password must be at least 8 characters long, contain one uppercase, one lowercase, one digit, and one special character
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateForm = () => {
    let newErrors = {};
    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!email || !validateEmail(email))
      newErrors.email = 'Invalid email address';
    if (!phone || !validatePhone(phone))
      newErrors.phone = 'Phone must be a valid 10-digit number';
    if (!password || !validatePassword(password)) {
      newErrors.password =
        'Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character';
    }
    if (password !== confirmPassword)
      newErrors.confirmPassword = 'Passwords do not match';
    if (!selectedOrganization)
      newErrors.organization = 'Please select an organization';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      showToastError('Please fix the form errors before submitting.');
      return;
    }

    const userData = {
      firstName,
      lastName,
      email,
      phone,
      password,
      organizations: [selectedOrganization], // Assuming a single organization for now
      schools: selectedSchools,
      image, // Include the image in the form data
    };

    onRegister(userData);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // If password is valid, clear the password error
    if (validatePassword(newPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: undefined,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          'Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character',
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // If passwords match, clear the confirm password error
    if (newConfirmPassword === password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: undefined,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Passwords do not match',
      }));
    }
  };

  return (
    <>
      {selectedOrganization && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImagePreview
            imageURL={
              organizations.find((org) => org._id === selectedOrganization)
                ?.imageInfo?.url
            }
            alt={`${organizations.find((org) => org._id === selectedOrganization)?.name}`}
            style={{ height: '100px', marginBottom: '1rem' }}
          />
          <Typography variant='h1' textAlign='center'>
            {organizations.find((org) => org._id === selectedOrganization)
              ?.name || ''}
          </Typography>
        </Box>
      )}
      <Paper
        elevation={3}
        style={{ padding: '1rem', maxWidth: '600px', margin: '1rem auto' }}
      >
        <Typography variant='h4' gutterBottom>
          Register
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{ marginBottom: '15px' }}
          gutterBottom
        >
          Create an account to start ordering for your school. If you already
          have an account, please log in. This resource is for schools only. If
          you are a parent, please contact your school's administrator. All
          accounts must be approved {selectedOrganization ? 'by ' : ''}
          {organizations.find((org) => org._id === selectedOrganization)?.name}.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box style={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label='First Name'
              variant='outlined'
              autoComplete='first-name'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={!!errors.firstName}
              helperText={errors.firstName}
              required
            />
            <TextField
              label='Last Name'
              autoComplete='last-name'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={!!errors.lastName}
              helperText={errors.lastName}
              required
            />

            <TextField
              label='Email'
              autoComplete='email'
              type='email'
              sx={{ flex: 1 }}
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
              required
            />
          </Box>
          <InputLabel id='school-label'>Profile Picture</InputLabel>
          <ImageUploader onFileSelect={setImage} />
          {/* <ImageUpload
            image={image}
            onImageChange={setImage}
            initialPreview={image && URL.createObjectURL(image)}
          /> */}
          <TextField
            label='Phone'
            autoComplete='phone'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin='dense'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label='Password'
              autoComplete='new-password'
              type='password'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              sx={{ flex: 1 }}
              value={password}
              onChange={handlePasswordChange}
              error={!!errors.password}
              helperText={errors.password}
              required
            />
            <TextField
              label='Confirm Password'
              autoComplete='confirm-password'
              type='password'
              variant='outlined'
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              required
            />
          </Box>
          {!organization && (
            <>
              <InputLabel id='organization-label'>
                Select Organization
              </InputLabel>
              <Select
                labelId='organization-label'
                value={selectedOrganization || ''}
                onChange={handleOrganizationChange}
                fullWidth
                margin='dense'
                required
                error={!!errors.organization}
                displayEmpty
              >
                <MenuItem value='' disabled>
                  Select an Organization
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org._id} value={org._id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {selectedOrganization && (
            <>
              <InputLabel id='school-label'>
                Select Schools You Will Order For
              </InputLabel>
              <Select
                multiple
                labelId='school-label'
                value={selectedSchools}
                onChange={handleSchoolChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {selected.map((school) => (
                      <Chip
                        key={school}
                        label={
                          schools.find((s) => s._id === school)?.name || ''
                        }
                        sx={{ margin: '2px 0' }} // Adjust spacing as needed
                      />
                    ))}
                  </Box>
                )}
                fullWidth
              >
                {schools.map((school) => (
                  <MenuItem key={school._id} value={school._id}>
                    <Checkbox
                      checked={selectedSchools.indexOf(school._id) > -1}
                    />
                    <ListItemText primary={school.name} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <Button
            type='submit'
            sx={{ marginTop: '1rem' }}
            variant='contained'
            color='primary'
            fullWidth
            // disabled={!isFormValid()} // Disable button based on form validation
          >
            Register
          </Button>
        </form>
        <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
          <Link href='/login' variant='body2'>
            Already have an account? Log in
          </Link>
        </Box>
      </Paper>
    </>
  );
};

export default RegisterForm;
