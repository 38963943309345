// src/pages/Register.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { register, clearUser } from '../features/auth';
import RegisterForm from '../components/Register/form';
import { showToastError, showToastSuccess } from '../utils/toastUtils';
import StatusModal from '../components/Register/StatusModal'; // Import the new modal component

const Register = ({ nlsfOrgId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = nlsfOrgId || queryParams.get('orgId');
  // State for managing modal visibility and message
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleRegister = async (userData) => {
    try {
      await dispatch(register(userData)).unwrap();
      showToastSuccess('Registration successful');
      dispatch(clearUser()); // Add this if your state management sets user data during registration

      // Show the modal with success message
      setModalMessage(
        'Registration successful! When your account is active you will receive an email with instructions.',
      );
      setOpenModal(true);
    } catch (err) {
      showToastError(err.message);
      setModalMessage(`Registration failed: ${err.message}`);
      setOpenModal(true); // Show modal with error message
    }
  };

  return (
    <>
      <RegisterForm organization={orgId} onRegister={handleRegister} />
      <StatusModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        message={modalMessage}
      />
    </>
  );
};

export default Register;
